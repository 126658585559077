.demo-image-preview {
  position: relative;
  text-align: center;
}

.demo-image-preview > img {
  width: 768px;
}

@media (max-width: 768px) {
  .demo-image-preview > img {
    width: 100%;
  }
}

/* fullscreen enable by props */
.demo-image-preview-fullscreen > img {
  width: 100vw;
  height: 100vh;
}
.react-html5-camera-photo {
  #container-circles {
    position: absolute;
    bottom: 130px;
  }
}
