html,
body {
  height: 100%;
}
#root {
  height: 100%;
  /* max-width: 500px; */
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}

#root::before {
  background: url("./assets/images/medical.png");
  background-size: 100px;
  background-repeat: repeat;
  opacity: 0.2;
  background-color: #26a0c959;
  content: "";

  width: 100%;
  height: 100%;
  position: fixed;
}
/* #root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #26a0c942;
} */

.App {
  text-align: center;
  min-height: 100%;
  box-shadow: 0px 0px 15px #444;
  max-width: 500px;
  width: 100%;
  height: auto;
  overflow-y: scroll;
  background: #e6e6e6;
  position: relative;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #26a0c959;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #26a0c9ad;
}
